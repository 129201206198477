.case-studies-listing {
    background-color: #131313;
    padding: 120px 0px;
    padding-bottom: 90px;
    margin-bottom: -105px;

    @media screen and (max-width: 768px) {
        margin-bottom: -100px;
        padding-bottom: 80px;
    }
    /*@media screen and (max-width: 425px) {
        margin-bottom: -85px;
    }*/




    .card {
        background-color: transparent;
        padding: 0.5rem;
        padding-bottom: 1rem;

        .card-header {
            position: relative;
            width: 650px;
            height: 489px;
            border-radius: 0px;
            padding: 0px;

            @media screen and (max-width: 1440px) {
                width: 100%;
                height: 389px;
            }

            @media screen and (max-width: 1024px) {
                height: 309px;
            }

            @media screen and (max-width: 768px) {
                height: 390px;
            }

            @media screen and (max-width: 425px) {
                height: 285px;
            }

            @media screen and (max-width: 375) {
                height: 246px;
            }





            .hover-blue {
                width: 100%;
                height: 100%;
                background: linear-gradient(0deg, rgba(10, 206, 89, 0.81), rgba(10, 206, 89, 0.81));
                opacity: 0;
                transition: opacity .3s linear;

                img {
                    width: 50px;
                    height: 60px;
                }
            }

            .card-img {
                cursor: pointer;
                border-radius: 0px;
                width: 100%;
                height: 100%;
                object-fit: cover;
                opacity: 1;
                transition: opacity .3s linear;
                top: 0;
                bottom: 0;
                position: absolute;
            }

            &:hover .hover-blue {
                opacity: 1;
            }

            &:hover .card-img {
                opacity: 0.2;
            }
        }



        .card-title {
            color: #fff;
            cursor: pointer;

            &:hover {
                color: #0ACE59;
            }
        }

        .card-body {
            padding: 1.25rem 0rem;

            .blog-date {
                opacity: 0.5;
            }

            a {
                cursor: pointer;
                font-family: 'Gotham';
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
                color: white;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        p {
            cursor: pointer;
            font-family: 'Gotham';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
        }

        .case-studies-btn a {
            background: #232323;

            &:hover {
                background: #0ACE59;
            }

            @media screen and (max-width: 768px) {
                margin-top: 10px;
            }
        }

        .min-height-200 {
            min-height: 200px;
        }

        .sub_title {
            font-weight: 700;
            font-size: 32px;
            line-height: 48px;
        }

        .blog-tag {
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.02em;
            text-transform: capitalize;
        }
    }

    ul.pagination {
        li.page-item a {
            background-color: transparent;
            color: #fff;
            border: none;
            border-radius: 0px;
            font-family: 'Gotham';
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            padding: 0.2rem 0.5rem;
            margin: 0px 0.8rem;
        }

        li.active a {
            color: #0ACE59;
            border-bottom: 3px solid #0ACE59;
        }

        li.disable a {
            opacity: 0.5;
        }
    }
}

.seo_strategy_col_custom {
    margin-bottom: -110px;

    @media screen and (max-width: 425px) {
        padding-top: 90px !important;
    }
}

.min-height-200 {
    min-height: 200px;
}

.custom-banner{
    @media screen and (max-width: 991px){
        height: auto;
    }
}