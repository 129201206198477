.lead_gen_options_col {
    padding: 300px 0 0;
    position: relative;
    background: #fff url(./assets/images/SEO_page/bg_circle_fade.png) no-repeat;
    background-position: 100%;
    background-size: contain;
    margin-bottom: -90px;

    &:before {
        content: "";
        background: url('/assets/images/SEO_page/arrow_bg.svg') center no-repeat !important;
        background-size: auto;
        background-position: center;
        position: absolute;
        top: -2px;
        left: 0;
        width: 100%;
        height: 180px;
        display: -webkit-flex;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        z-index: 99;
        margin: 0 auto;
    }

    .leadOptions {
        margin-bottom: 130px;

        img {
            max-height: 130px;
            max-width: 130px;
            min-height: 130px;
            overflow: hidden;
            display: block;
            margin: 0 auto;
        }

        h5 {
            font-family: Gotham;
            font-weight: bold;
            font-size: 32px;
            line-height: 48px;
            text-align: center;
            color: #131313;
            margin-top: 40px;
        }
    }
}

.journey_steps {
    margin-bottom: 50px;
    .media-body {
      h5 {
        font-family: Gotham;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: #131313;
        margin: 0 0 20px 0px;
      }
      p {
        font-family: Gotham;
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        color: #68696f;
      }
    }
  }
