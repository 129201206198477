.con {
  width: auto;
  margin: 0 auto;
  cursor: pointer;
  display: inline-block;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  position: relative;
  z-index: 999;

  &:hover {
    .bar {
      background-color: #0ace59;
    }

    .arrow-top {
      -webkit-transform: rotateZ(50deg) translateY(7px);
      -moz-transform: rotateZ(50deg) translateY(7px);
      -ms-transform: rotateZ(50deg) translateY(7px);
      -o-transform: rotateZ(50deg) translateY(7px);
      transform: rotateZ(50deg) translateY(7px);
      width: 25px;
    }

    .arrow-middle {
      -webkit-transform: translateX(-18px);
      -moz-transform: translateX(-18px);
      -ms-transform: translateX(-18px);
      -o-transform: translateX(-18px);
      transform: translateX(-18px);
    }

    .arrow-bottom {
      -webkit-transform: rotateZ(-50deg) translateY(-7px);
      -moz-transform: rotateZ(-50deg) translateY(-7px);
      -ms-transform: rotateZ(-50deg) translateY(-7px);
      -o-transform: rotateZ(-50deg) translateY(-7px);
      transform: rotateZ(-50deg) translateY(-7px);
      width: 25px;
    }
  }
}

.middle {
  margin: 0 auto;
}

.bar {
  display: block;
  height: 3px;
  width: 29px;
  background: #fff;
  margin: 10px auto;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.bar.arrow-bottom,
.bar.arrow-top {
  width: 22px;
  margin: 0 0 0 auto;
}

.Overlay_Menu {
    height: 0;
    width: 100%;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    background: #131313;
    overflow-x: hidden;
    transition: 0.5s;

    .reduceSpace {
        margin-top: -2px;
    }

    .closebtn {
        position: absolute;
        top: 20px;
        right: 35px;
        line-height: normal;
        font-size: 60px;
        transform-origin: center;
        color: #fff;
        text-decoration: none;
        z-index: 999;
        transition: 0.4s;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);

        @media screen and (max-width:991px) {
            font-size: 50px;
            right: 10px;
        }

        &:hover {
            color: #131313;
            -webkit-transform: rotate(-180deg);
            -moz-transform: rotate(-180deg);
            -o-transform: rotate(-180deg);
            transform: rotate(-180deg);
            text-decoration: none;
        }
    }

    .Overlay_Menu_Content {
        width: 100%;
        height: 100%;
        text-align: left;
        padding: 0 80px;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
        word-break: break-word;

        @media screen and (max-width: 991px) {
            padding: 30px;
            height: 85vh;
            overflow-y: auto;
        }

        .LeftNavMenu {
            margin: 0;
            padding: 0;

            li {
                display: none;
                list-style: none;

                &:first-child {
                    margin: 45px 0;

                    @media screen and (max-width: 991px) {
                        margin: 0 0 45px;
                    }
                }

                a {
                    font-family: Gotham;
                    font-weight: bold;
                    font-size: 40px;
                    line-height: 64px;
                    color: #cfd1d7;
                    display: inline-block;
                    position: relative;
                    text-decoration: none;
                    background: #131313;

                    @media screen and (max-width: 991px) {
                        font-size: 30px;
                        line-height: 55px;
                    }

                    &:hover {
                        &:after {
                            opacity: 1;
                            text-decoration: none;
                            color: #fff;
                            width: 70px;
                        }
                    }

                    &:after {
                        content: "";
                        width: 0px;
                        height: 3px;
                        background: #0ace59;
                        position: absolute;
                        top: 55%;
                        left: 110%;
                        opacity: 0;
                        -webkit-transition: 0.3s linear;
                        -moz-transition: 0.3s linear;
                        -o-transition: 0.3s linear;
                        transition: 0.3s linear;
                    }
                }

                a.active {
                    &:after {
                        content: "";
                        height: 3px;
                        background: #0ace59;
                        position: absolute;
                        top: 55%;
                        left: 110%;
                        -webkit-transition: 0.3s linear;
                        -moz-transition: 0.3s linear;
                        -o-transition: 0.3s linear;
                        transition: 0.3s linear;
                        opacity: 1;
                        text-decoration: none;
                        color: #fff;
                        width: 70px;
                    }
                }
            }
        }

        .mail_outer {
            position: sticky;
            bottom: 0;
            left: 0;
            background: #131313;

            .Menu_mail_col {
                position: relative;
                padding: 0 0 50px 100px;
                display: none;

                @media screen and (max-width:991px) {
                    padding-left: 0;
                }

                &:before {
                    content: "";
                    width: 70px;
                    height: 2px;
                    background: #0ace59;
                    position: absolute;
                    top: 18px;
                    left: 0;

                    @media screen and (max-width:991px) {
                        display: none;
                    }
                }

                h6 {
                    font-family: Gotham;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 26px;
                    color: #68696f;
                    margin-bottom: 0;
                }

                a {
                    font-family: Gotham;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 30px;
                    color: #68696f;
                    transition: 0.3s linear;
                }
            }
        }
    }
}

a.navbar-brand {
  @media screen and (max-width: 991px) {
    max-width: 95px;
    height: auto;
  }
}

.custom_fullHeight {
  height: 100vh;
}

.bg_greenMenu {
    background: #0ace59;
    overflow-y: auto;
    display: -webkit-flex;
    display: flex;
    align-items: center;

    .Green_Menu {
        background: #0ace59;
        padding: 130px 50px 50px;
        width: 100%;
        height: 100%;
        display: none;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (max-width: 991px) {
            padding-top: 50px;
        }

        h6 {
            font-family: Gotham;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            color: #ffffff;
            margin-bottom: 40px;
            display: block;
        }

        .NavMenuOptions {
            margin: 0;
            padding: 0;

            li {
                display: block;

                a {
                    font-family: Gotham;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    color: #ffffff;
                    text-decoration: none;
                    transition: 0.5s linear;
                    position: relative;
                    padding: 0 0 0 45px;
                    margin-bottom: 20px;
                    display: inline-block;
                    overflow: hidden;
                    word-break: break-word;

                    &:before {
                        content: "\f272";
                        font-family: bootstrap-icons !important;
                        position: absolute;
                        top: 0px;
                        left: 0;
                        line-height: normal;
                        color: #fff;
                        font-size: 24px;
                        transition: 0.5s linear;
                    }

                    &:hover {
                        color: #131313;
                        text-decoration: none;

                        &:before {
                            color: #131313;
                        }
                    }
                }
            }
        }

        .Social_Menu {
            ul {
                margin: 0;
                padding: 0;

                li {
                    display: inline-block;
                    margin-right: 33px;

                    a {
                        color: #fff;
                        font-size: 30px;
                        transition: 0.5s linear;

                        &:hover {
                            color: #131313;
                        }
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
