.marketing_stats_section {
  padding: 100px 0;
  @media screen and (max-width: 767px) {
    padding-bottom: 0;
  }
  .marketing_stats_card {
    margin-bottom: 80px;

    .icon_img {
      width: 60px;
      height: 60px;
      background: #f3f3f3;
      display: -webkit-flex;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      margin-bottom: 15px;
    }

    h4 {
      font-family: Gotham;
      font-weight: bold;
      font-size: 32px;
      line-height: 48px;
      color: #131313;
      margin-bottom: 20px;
    }
    p {
      font-family: Gotham;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #68696f;
    }
  }
}

.card2 {
  margin-top: 180px;
  @media screen and (max-width: 767px) {
    margin-top: 0;
  }
}

.card3 {
  margin-top: -135px;
  
  @media (min-width:992px) and (max-width:1199px) {
    margin-top: -180px;
  }
  @media (min-width:768px) and (max-width:991px) {
    margin-top: -120px;
  }
  @media screen and (min-width: 1600px) {
    margin-top: -125px;
  }
}

.expert_team_col {
  position: relative;
  background: #0ace59;
  padding: 187px 0px 210px;
  -webkit-clip-path: polygon(0% 0%, 100% 8%, 100% 100%, 0% 92%);
  clip-path: polygon(0% 0%, 100% 8%, 100% 100%, 0% 92%);
  margin-top: 43px;
  margin-bottom: -100px;

  @media screen and (max-width: 991px) {
    margin-bottom: -120px;
    padding-top: 120px;
    padding-bottom: 300px;
  }

  &:after {
    content: "";
    background: url(./assets/images/lead_page/Mail_sent.webp) no-repeat;
    background-position: right bottom;
    background-size: auto;
    position: absolute;
    bottom: -7px;
    right: 10%;
    width: 500px;
    display: block;
    height: 425px;

    @media screen and (max-width: 767px) {
      height: 250px;
      width: 100%;
      background-size: contain;
      bottom: 30px;
      right: 0;
    }
  }

  .speak_to_ceo {
    p {
      font-family: Gotham;
      font-weight: bold;
      font-size: 32px;
      line-height: 48px;
      color: #ffffff;

      @media screen and (max-width: 576px) {
        font-size: 26px;
        line-height: 40px;
        margin-top: 30px;
      }
    }
  }
}

.is_website_col {
  background: #212225;
  -webkit-clip-path: polygon(0% 0%, 100% 8%, 100% 100%, 0% 92%);
  clip-path: polygon(0% 0%, 100% 8%, 100% 100%, 0% 92%);
  padding: 194px 0px 160px;
  margin-bottom: -107px;
  @media screen and (max-width: 991px) {
    -webkit-clip-path: polygon(0% 0%, 100% 5%, 100% 100%, 0% 93%);
    clip-path: polygon(0% 0%, 100% 5%, 100% 100%, 0% 93%);
    margin-bottom: -132px;
  }
}

.business_strategy_col {
  background: #131313 url(../images/SEO_page/bg_circle.png) no-repeat;
  background-position: center;
  background-size: contain;
  padding: 197px 0px;
  -webkit-clip-path: polygon(0% 0%, 100% 6%, 100% 100%, 0% 93%);
  clip-path: polygon(0% 0%, 100% 6%, 100% 100%, 0% 93%);
  margin-bottom: -110px;


  @media screen and (max-width: 991px) {
    -webkit-clip-path: polygon(0% 0%, 100% 4%, 100% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 100% 4%, 100% 100%, 0% 100%);
    margin-bottom: -150px;
  }

  .business_strategy_ideas {
    p {
      font-family: Gotham;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #cfd1d7;
      margin-bottom: 2rem;
    }
  }

  .business_strategy_List {
    ul {
      margin: 0;
      padding: 0;
      li {
        display: block;
        padding: 0 0 30px 55px;
        position: relative;

        p {
          font-family: Gotham;
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          color: #cfd1d7;
          margin-bottom: 0;
        }

        &:before {
          content: "\f270";
          font-family: bootstrap-icons !important;
          color: #0ace59;
          position: absolute;
          top: 0;
          left: 0;
          font-size: 26px;
        }
      }
    }
  }
}
