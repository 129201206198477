* {
    padding: 0;
    margin: 0;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Gotham', sans-serif;
    background: #fff;
}

img {
    max-width: 100%;
}

h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0;
}

button:focus {
    outline: none;
}

a:focus {
    outline: none;
}

.container {
    @media screen and (min-width:1600px) {
        max-width: 1400px;
    }
}


.Global_heading {
    font-weight: 800;
    font-size: 80px;
    font-family: 'Gotham';
    line-height: 96px;
    color: #0ACE59;
    padding-bottom: 15px;
    overflow: hidden;
    word-break: break-word;

    @media screen and (max-width:1400px) {
        font-size: 50px;
        line-height: 65px;
    }

    @media screen and (max-width:991px) {
        line-height: 60px;
    }

    @media screen and (max-width:767px) {
        font-size: 32px;
        line-height: 45px;
    }
}

.Sub_heading {
    font-family: Gotham;
    font-weight: bold;
    font-size: 40px;
    line-height: 60px;
    color: #FFFFFF;
    overflow: hidden;
    word-break: break-word;

    @media screen and (max-width:991px) {
        padding-right: 0px;
    }

    @media screen and (max-width:767px) {
        font-size: 30px;
        line-height: 44px;
    }

    @media screen and (max-width:576px) {
        font-size: 25px;
        line-height: 38px;
    }
}

.sub_heading_2 {
    font-family: Gotham;
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: #FFFFFF;
}

.Sub_heading_black {
    font-family: Gotham;
    font-weight: bold;
    font-size: 40px;
    line-height: 60px;
    color: #000;

    @media screen and (max-width:767px) {
        font-size: 30px;
        line-height: 40px;
    }
}

.subTitle2 {
    font-family: Gotham;
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    color: #FFFFFF;
}

.text_content {
    margin-bottom: 50px;

    p {
        font-family: Gotham;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #CFD1D7;
        margin-bottom: 35px;
    }
}

.text_content_2 {
    p {
        font-family: Gotham;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #CFD1D7;
        margin-bottom: 40px;
    }
}

.col {
    display: inline-block;
    width: auto;
    text-align: center;
    height: auto;
    position: relative;
}

.form-group {
    position: relative;
    padding: 0 !important;
}

#gtbutton {
    position: fixed;
    bottom: 90px;
    right: 100px;
    transition: background-color .3s, opacity .5s, visibility .5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
    display: block;
    cursor: pointer;

    @media screen and (max-width:767px) {
        bottom: 20px;
        right: 20px;
    }
}

#gtbutton.show {
    opacity: 1;
    visibility: visible;
}

.text_green {
    color: #0ACE59 !important;
}

.text_grey_2 {
    color: #68696F !important;
}

.dark_text {
    color: #131313 !important;
}

.section_subHeading {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #131313;
}

.Global_section_two {
    background: #131313;
    margin-top: -3px;
    padding: 100px 0 180px;
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 92%);
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 92%);

    @media screen and (max-width:767px) {
        padding-bottom: 100px;
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 97%);
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 97%);
    }
}

.black-bg-crical {
    position: relative;

    &:before,
    &:after {
        content: "";
        background-size: contain;
        width: 190px;
        height: 100%;
        background-position: left 50%;
        position: absolute;
        background-repeat: no-repeat;
        bottom: 0px;
        opacity: 0.1;
    }

    :before {
        background-image: url(./assets/images/seo-sectio-vector-left.png);
    }

    :after {
        background-image: url(./assets/images/seo-sectio-vector-righ.png);
        right: 0px;
        top: 0px;
        background-position: top;
        width: 250px;
    }
}

.attention_col {
    padding: 47px;
    position: relative;

    @mixin border($bgImage,$top,$right,$bottom,$left) {
        content: "";
        background: $bgImage;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top left;
        position: absolute;
        top: $top;
        left: $left;
        right: $right;
        bottom: $bottom;
        display: block;
        width: 100px;
        height: 100px;
    }

    &:before {
        @include border (url(../images/SEO_page/border_before.png),0,unset,unset,0);
        background: url('../images/SEO_page/border_before.png') center no-repeat !important;
    }

    &:after {
        @include border (url(../images/SEO_page/border_after.png),unset,0,0,unset);
        background: url('../images/SEO_page/border_after.png') center no-repeat !important;
    }
}

.common_mobile_layout {
    @media screen and (max-width:991px) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100vh;
    }
}

.bg-gradient {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.headquarters {
    padding: 100px 0px;
    background: #131313;


    @media screen and (max-width:768px) {
        padding: 80px 0px;
    }

    h3 {
        font-family: 'Gotham';
        font-weight: 400;
        font-size: 32px;
        line-height: 48px;

        @media screen and (max-width:768px) {
            font-size: 28px;
        }

        @media screen and (max-width:425px) {
            padding-bottom: 3rem;
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.page-contact-us {
    background-color: #131313;
}
