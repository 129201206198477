.bn_content_case-study-detail {
    text-align: center;

    h1 {
        font-size: 80px;
        line-height: 96px;

        @media screen and (max-width: 768px) {
            font-size: 70px;
        }

        @media screen and (max-width: 425px) {
            font-size: 50px;
            line-height: 67px;
        }
    }
}

.case-study-detail-layout {
    @media screen and (max-width: 768px) {
        height: auto;
    }
}

.case-study-detail-about {
    padding: 130px 0px;
    background: #131313 url(../images/SEO_page/bg_circle.png) no-repeat;
    margin-bottom: -125px;

    h2 {
        font-size: 32px;
        line-height: 48px;
    }

    h2.about-content {
        margin-bottom: 5rem;
    }

    p {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: #fff;
    }

    ul {
        list-style-image: url("../images/case-studies/icon-checked.svg");
        margin-left: 27px;

        li {
            font-weight: 400;
            font-size: 24px;
            line-height: 58px;
            color: #CFD1D7;
        }
    }
}

.color-gray {
    color: #CFD1D7 !important;
}

.section-result {
    padding: 100px 0px !important;
    .item-result {
        padding-top: 20px;
        h2.item-result-title {
            font-size: 40px;
            line-height: 60px;
            color: #0ACE59;
            font-weight: bold;
        }

        p {
            font-size: 20px;
            line-height: 30px;
            color: #050505;
            font-weight: 500;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            padding: 3rem 0rem;
        }

    }


}