.agency-sections {
    background: #212225;
    padding: 257px 0px;
    -webkit-clip-path: polygon(0% 0%, 100% 8%, 100% 100%, 0% 92%);
    clip-path: polygon(0% 0%, 100% 8%, 100% 100%, 0% 92%);
    // margin-top: 43px;
    margin-bottom: -100px;

    @media (min-width:992px)and (max-width:1199px) {
        padding: 187px 0;
    }

    @media screen and (max-width: 576px) {
        padding: 140px 0;
        -webkit-clip-path: polygon(0 0, 100% 8%, 100% 100%, 0 94%);
        clip-path: polygon(0 0, 100% 8%, 100% 100%, 0 94%);
    }

    .Global_heading {
        padding-right: 30px;
    }

    .text_content {
        margin-top: 20px;

        p {
            color: #fff;
        }
    }

    .highlight {
        font-family: Gotham;
        font-style: normal;
        font-weight: 800;
        font-size: 28px;
        line-height: 55px;
        text-transform: uppercase;
        color: #050505;
        display: table;
        background: #ffffff;
        clear: both;
        padding: 0px 14px;
        margin-bottom: 20px;
        position: relative;
        top: 6px;

        @media screen and (max-width: 767px) {
            font-size: 25px;
            line-height: 33px;
            padding: 10px;
        }
    }
}

.online-enquiry {
    background: #050505;
    padding: 170px 0px 80px;

    .google-map {
        margin-left: 60px;
        margin-top: 30px;

        @media screen and (max-width:991px) {
            margin-left: 0;
        }

        iframe {
            border: 3px solid #ffffff !important;
            box-sizing: border-box;
            border-radius: 10px;
            overflow: hidden;
        }
    }

    form {
        margin-top: 58px;

        .form-control {
            border: none;
            outline: none;
            box-shadow: none;
            border-bottom: 1px solid #cfd1d7;
            background: none;
            padding: 12px 0px;
            border-radius: 0px;

            &::-webkit-input-placeholder,
            &::-moz-placeholder,
            &::-ms-input-placeholder,
            &::-moz-placeholder {
                color: #ffffff;
                opacity: 1;
            }
        }

        .form-group {
            padding: 0px 20px;
            margin-bottom: 66px;
        }
    }

    .find_more_btn {
        a {
            font-family: Gotham;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            margin-top: 15px;
        }
    }

    .contact-list {
        margin-top: 50px;

        li {
            font-family: Gotham;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            color: #cfd1d7;
            list-style: none;
            position: relative;
            margin-bottom: 27px;
            padding-left: 55px;

            @media screen and (max-width:767px) {
                display: flex;
                flex-direction: column;
            }

            &:before {
                content: "";
                width: 30px;
                display: inline-block;
                height: 2px;
                background: #0acd5a;
                margin-right: 20px;
                position: absolute;
                top: 50%;
                left: 0;
            }

            a {
                font-family: Gotham;
                font-style: normal;
                font-weight: bold;
                font-size: 22px;
                line-height: 48px;
                color: #fff;
                overflow: hidden;
                word-break: break-word;

                @media screen and (max-width:767px) {
                    margin-left: 0;
                    font-size: 26px;
                    white-space: normal;
                }
            }
        }
    }
}

.online-enquiry {
    form {
        .form-group {
            padding: 0px 20px;
            margin-bottom: 66px;
        }
    }

    .form-label {
        position: absolute;
        left: 0;
        top: 10px;
        color: #cfd1d7;
        font-family: "Gotham";
        font-size: 18px;
        z-index: 10;
        transition: transform 150ms ease-out, font-size 150ms ease-out;
    }

    .form-input {
        position: relative;
        padding: 12px 0px;
        width: 100%;
        outline: 0;
        color: #fff;
        background: transparent !important;
        border: 0;
        box-shadow: 0 1px 0 0 #e5e5e5;
        transition: box-shadow 150ms ease-out;
        -webkit-box-shadow: 0 1px 0 0 #e5e5e5;
        -moz-box-shadow: 0 1px 0 0 #e5e5e5;
        transition: box-shadow 150ms ease-out;
        -webkit-appearance: none;
    }
}

.serviceprovider-slider {
    text-align: center;
    margin-top: 125px;

    .contant {
        position: relative;
        max-width: 1140px;
        margin: auto;
        margin-bottom: 92px;

        p {
            font-family: Gotham;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            color: #68696f;
        }

        &:before {
            content: "";
            width: 61px;
            height: 5px;
            background: #0ace59;
            display: table;
            margin: auto;
            margin-bottom: 28px;
        }
    }

    .sub_heading {
        font-family: Gotham;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 60px;
        text-align: center;
        color: #cfd1d7;
        padding-bottom: 32px;
        max-width: 880px;
        margin: auto;

        @media screen and (max-width:767px) {
            font-size: 30px;
            line-height: 42px;
        }
    }

    .owl-dots {
        button {
            background: #68696f;
            width: 10px;
            height: 10px;
            border-radius: 100px;
            margin: 0px 10px;
        }

        button.active {
            border: 1px solid #ffffff;
            background: transparent;
        }
    }
}

footer {
    background: #212225;
    padding: 26px 0px;

    .col-list {
        @media screen and (max-width: 375px) {
            padding-top: 2rem;
        }
    }

    .title-menu-footer {
        font-family: 'Gotham';
        font-weight: 450;
        font-size: 20px;
        line-height: 30px;

        @media screen and (max-width: 375px) {
            text-align: left;
        }
    }

    ul {
        list-style: none;
        padding: 1rem 0px;
        columns: 2;

        @media screen and (max-width: 375px) {
            columns: 1;
            text-align: left;
            padding: 0px;
        }
    }

    ul li {
        padding: 3px 0px;

        a {
            font-family: 'Gotham';
            font-weight: 325;
            font-size: 16px;
            line-height: 34px;
            color: #fff;
            text-decoration: none !important;

            &:hover {
                color: #0ACE59;
            }
        }
    }

    .content {
        text-align: center;
        height: 100%;
        align-items: center;
        justify-content: start;

        @media screen and (max-width: 375px) {
            display: block;
            justify-content: center;
            text-align: center;
        }

        p , a{
            font-family: Gotham;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 26px;
            color: #cfd1d7;
        }
    }
}
