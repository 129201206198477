header.header_404 {
    background-color: #131313;
    color: #fff;
    height: 100vh;

    @media screen and (max-width: 768px) {
        height: auto;
    }

    .common_mobile_layout {
        @media screen and (max-width: 768px) {
            height: auto;
        }
    }

    .center {
        text-align: center;
    }

    .image_404 {
        object-fit: cover;
        max-width: 35rem;
        max-height: 25rem;

        @media screen and (max-width: 425px) {
            width: 18rem;
        }
    }

    h6 {
        font-weight: 700;
        font-size: 40px;
        line-height: 60px;
    }

    p {
        margin: 0 auto;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        max-width: 800px;
    }
}
