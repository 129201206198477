@mixin StepBox($top, $right, $bottom, $left) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin StepsImg {
  width: 95px;
  height: 95px;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

@mixin Process_Steps($direction) {
  display: -webkit-flex;
  display: flex;
  flex-direction: $direction;
  justify-content: space-between;
  align-items: center;
  min-height: 270px;
  max-width: 150px;
  margin: 0 auto;
  overflow: hidden;
  word-break: break-word;
}

@mixin Labels {
  font-family: Gotham;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #cfd1d7;
  margin: 0 auto;
  max-width: 95%;
  overflow: hidden;
  word-break: break-word;
}

@mixin InfographicSteps($top, $right, $bottom, $left) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin Boxtitle($aligntext) {
  font-family: Jost;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.34em;
  text-transform: uppercase;
  color: #353535;
  margin-bottom: 15px;
  text-align: $aligntext;
}

@mixin stepBox_even($direction, $topValue, $bottomValue, $rightValue) {
  flex-direction: $direction;
  width: 300px;
  justify-content: space-between;
  max-width: 400px;
  min-height: unset;
  left: -142px;
  top: $topValue;
  bottom: $bottomValue;
  right: $rightValue;
}

@mixin InfoSteps_content {
  background: #353535;
  padding: 30px;
  word-break: break-word;
  min-width: 400px;
  position: relative;

  h2 {
    font-family: Gotham;
    font-weight: bold;
    font-size: 40px;
    line-height: 60px;
    color: #0ace59;
    margin-bottom: 14px;
    text-transform: capitalize;
  }

  ul {
    margin: 0;
    padding: 0;
    li {
      display: block;
      position: relative;
      padding: 0 0 4px 35px;

      p {
        font-family: Gotham;
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        color: #cfd1d7;
        margin-bottom: 0;

        &:after {
          content: "\f272";
          font-family: bootstrap-icons !important;
          position: absolute;
          top: 0;
          left: 0;
          color: #fff;
          font-size: 20px;
        }
      }
    }
  }
}

.Paid_info_outer {
  background: #131313;
  padding: 120px 0 140px;
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 92%);
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 92%);
  margin-top: -3px;

  @media screen and (max-width: 767px) {
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 98%, 0 94%);
    clip-path: polygon(0 0, 100% 0%, 100% 98%, 0 94%);
  }
}

// INFOGRAPHICS SECTION BEGIN

.ad_process_outer {
  min-height: 420px;
  display: flex;
  align-items: center;
  margin: 70px auto 0;

  @media (min-width: 768px) and (max-width: 991px) {
    padding-bottom: 60px;
  }

  .ad_process_inner {
    position: relative;
    display: block;
    width: 100%;

    @media screen and (max-width: 767px) {
      max-width: 19px;
      margin: auto;
      display: inline-block;
    }

    .ad_process_step_up {
      @include Process_Steps(column);

      @media (min-width: 768px) and (max-width: 991px) {
        max-width: 92px;
        min-height: 210px;
      }

      @media (min-width: 992px) and (max-width: 1199px) {
        max-width: 110px;
      }
      @media (min-width: 1200px) and (max-width: 1599px) {
        max-width: 130px;
      }

      label {
        @include Labels;
        @media screen and (max-width: 767px) {
          margin: 0;
          max-width: 110px;
        }

        @media (min-width: 768px) and (max-width: 991px) {
          margin: 0;
          max-width: 110px;
          padding: 0 5px;
          font-size: 14px;
          line-height: normal;
          word-break: normal;
        }
      }

      .stepImg_up {
        @include StepsImg;

        @media (min-width: 768px) and (max-width: 991px) {
          width: 75px;
          height: 75px;

          img {
            width: 45px;
            height: 45px;
          }
        }

        &:before {
          content: "";
          width: 12px;
          height: 12px;
          border: 2px solid #fff;
          border-radius: 50%;
          position: absolute;
          bottom: -100%;
          z-index: 9;
          right: unset;
          left: unset;
          top: unset;

          @media screen and (max-width: 767px) {
            bottom: 46%;
            left: -60px;
          }
        }

        &:after {
          content: "";
          width: 4px;
          height: 100px;
          position: absolute;
          bottom: -100%;
          right: unset;
          left: unset;
          top: unset;

          @media screen and (max-width: 767px) {
            width: 65px;
            height: 4px;
            bottom: 50%;
            left: -58px;
          }
        }
      }

      .stepImg_2 {
        background: #c25252;
        &:before,
        &:after {
          background: #c25252;
        }
      }

      .stepImg_4 {
        background: #1a7fdd;
        &:before,
        &:after {
          background: #1a7fdd;
        }
      }

      .stepImg_6 {
        background: #a959ce;
        &:before,
        &:after {
          background: #a959ce;
        }
      }
    }

    .stepImg_1,
    .stepImg_8 {
      background: #fff;
      border-radius: 50%;
      width: 102px;
      height: 102px;
      display: -webkit-flex;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 15px;
      @media screen and (max-width: 767px) {
        width: 85px;
        height: 85px;
        margin: 0 0 0 10px;
      }

      @media (min-width: 768px) and (max-width: 991px) {
        width: 68px;
        height: 68px;
      }

      @media (min-width: 992px) and (max-width: 1199px) {
        width: 80px;
        height: 80px;
      }

      img {
        @media (min-width: 768px) and (max-width: 991px) {
          max-width: 48px;
        }
        @media (min-width: 992px) and (max-width: 1199px) {
          max-width: 50px;
        }
      }
    }

    .ad_process_step_down {
      @include Process_Steps(column-reverse);

      @media (min-width: 768px) and (max-width: 991px) {
        max-width: 92px;
        min-height: 210px;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        max-width: 110px;
      }
      @media (min-width: 1200px) and (max-width: 1599px) {
        max-width: 130px;
      }

      label {
        @include Labels;
        @media screen and (max-width: 767px) {
          margin: 0;
          max-width: 110px;
        }

        @media (min-width: 768px) and (max-width: 991px) {
          margin: 0;
          max-width: 110px;
          padding: 0 5px;
          font-size: 14px;
          line-height: normal;
          word-break: normal;
        }
      }

      .stepImg_down {
        @include StepsImg;

        @media (min-width: 768px) and (max-width: 991px) {
          width: 75px;
          height: 75px;

          img {
            width: 45px;
            height: 45px;
          }
        }

        &:before {
          content: "";
          width: 4px;
          height: 100px;
          position: absolute;
          top: -100%;
          right: unset;
          left: unset;
          bottom: unset;

          @media screen and (max-width: 767px) {
            bottom: unset;
            right: -60px;
            width: 70px;
            height: 4px;
            top: 50%;
          }
        }

        &:after {
          content: "";
          width: 12px;
          height: 12px;
          border: 2px solid #fff;
          border-radius: 50%;
          position: absolute;
          top: -100%;
          z-index: 9;
          right: unset;
          left: unset;
          bottom: unset;

          @media screen and (max-width: 767px) {
            top: 46%;
            right: -60px;
          }
        }
      }

      .stepImg_3 {
        background: #e4b63f;
        &:before,
        &:after {
          background: #e4b63f;
        }
      }

      .stepImg_5 {
        background: #36bc64;
        &:before,
        &:after {
          background: #36bc64;
        }
      }

      .stepImg_7 {
        background: #7221d9;
        &:before,
        &:after {
          background: #7221d9;
        }
      }
    }
    .steps_box1 {
      @include StepBox(unset, unset, -44px, 35px);
      text-align: center;

      @media screen and (max-width: 767px) {
        @include StepBox(20px, unset, unset, -145px);
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
        height: 130px;
        width: 210px;
      }

      @media (min-width: 768px) and (max-width: 991px) {
        @include StepBox(unset, unset, -50px, -4px);
      }

      @media (min-width: 992px) and (max-width: 1199px) {
        @include StepBox(unset, unset, -75px, 10px);
      }
      @media (min-width: 1200px) and (max-width: 1599px) {
        @include StepBox(unset, unset, -52px, 20px);
      }

      label {
        @include Labels;
        @media screen and (max-width: 767px) {
          margin: 0;
          max-width: 110px;
        }

        @media (min-width: 768px) and (max-width: 991px) {
          margin: 0;
          max-width: 110px;
          padding: 0 5px;
          font-size: 14px;
          line-height: normal;
          word-break: normal;
        }
      }
    }
    .steps_box2 {
      @include StepBox(-133px, unset, unset, 183px);

      @media screen and (max-width: 767px) {
        @include stepBox_even(row-reverse, 210px, unset, unset);
      }

      @media (min-width: 768px) and (max-width: 991px) {
        @include StepBox(-120px, unset, unset, 83px);
      }

      @media (min-width: 992px) and (max-width: 1199px) {
        @include StepBox(-150px, unset, unset, 119px);
      }
      @media (min-width: 1200px) and (max-width: 1599px) {
        @include StepBox(-143px, unset, unset, 142px);
      }
    }
    .steps_box3 {
      @include StepBox(unset, unset, -133px, 375px);

      @media screen and (max-width: 767px) {
        @include stepBox_even(row, 380px, unset, unset);
        bottom: unset;
      }

      @media (min-width: 768px) and (max-width: 991px) {
        @include StepBox(unset, unset, -120px, 170px);
      }

      @media (min-width: 992px) and (max-width: 1199px) {
        @include StepBox(unset, unset, -150px, 238px);
      }
      @media (min-width: 1200px) and (max-width: 1599px) {
        @include StepBox(unset, unset, -143px, 295px);
      }
    }
    .steps_box4 {
      @include StepBox(-133px, unset, unset, 528px);

      @media screen and (max-width: 767px) {
        @include stepBox_even(row-reverse, 550px, unset, unset);
      }
      @media (min-width: 768px) and (max-width: 991px) {
        @include StepBox(-120px, unset, unset, 256px);
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        @include StepBox(-150px, unset, unset, 351px);
      }
      @media (min-width: 1200px) and (max-width: 1599px) {
        @include StepBox(-143px, unset, unset, 421px);
      }
    }
    .steps_box5 {
      @include StepBox(unset, 524px, -133px, unset);

      @media screen and (max-width: 767px) {
        @include stepBox_even(row, unset, 550px, unset);
      }
      @media (min-width: 768px) and (max-width: 991px) {
        @include StepBox(unset, 256px, -120px, unset);
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        @include StepBox(unset, 351px, -150px, unset);
      }
      @media (min-width: 1200px) and (max-width: 1599px) {
        @include StepBox(unset, 421px, -143px, unset);
      }
    }
    .steps_box6 {
      @include StepBox(-133px, 353px, unset, unset);

      @media screen and (max-width: 767px) {
        @include stepBox_even(row-reverse, 890px, unset, unset);
      }
      @media (min-width: 768px) and (max-width: 991px) {
        @include StepBox(-120px, 170px, unset, unset);
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        @include StepBox(-150px, 236px, unset, unset);
      }
      @media (min-width: 1200px) and (max-width: 1599px) {
        @include StepBox(-143px, 281px, unset, unset);
      }
    }
    .steps_box7 {
      @include StepBox(unset, 176px, -133px, unset);
      @media screen and (max-width: 767px) {
        @include stepBox_even(row, unset, 200px, unset);
      }
      @media (min-width: 768px) and (max-width: 991px) {
        @include StepBox(unset, 83px, -120px, unset);
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        @include StepBox(unset, 120px, -150px, unset);
      }
      @media (min-width: 1200px) and (max-width: 1599px) {
        @include StepBox(unset, 143px, -143px, unset);
      }
    }
    .steps_box8 {
      @include StepBox(unset, -10px, -70px, unset);
      text-align: center;

      @media screen and (max-width: 767px) {
        @include StepBox(unset, -38px, 20px, unset);
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
        height: 130px;
        width: 210px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        @include StepBox(unset, -10px, -60px, unset);
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        @include StepBox(unset, -40px, -75px, unset);
      }
      @media (min-width: 1200px) and (max-width: 1599px) {
        @include StepBox(unset, -28px, -75px, unset);
      }

      label {
        @include Labels;
        @media screen and (max-width: 767px) {
          margin: 0;
          max-width: 110px;
        }

        @media (min-width: 768px) and (max-width: 991px) {
          margin: 0;
          max-width: 110px;
          padding: 0 5px;
          font-size: 14px;
          line-height: normal;
          word-break: normal;
        }
      }
    }
  }
}

// INFOGRAPHICS SECTION END

.google_keyword_outer {
  padding: 150px 0;
  @media screen and (max-width: 767px) {
    padding: 20px 0 0px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 50px 0 0px;
  }

  .left_green_column {
    background: #0ace59;
    padding: 60px;
    overflow: hidden;
    word-break: break-word;

    @media screen and (max-width: 767px) {
      padding: 30px;
    }

    .sub_heading_2 {
      @media screen and (max-width: 767px) {
        font-size: 23px;
        line-height: 35px;
        font-weight: 500;
      }
    }
  }
  .media-body {
    p {
      font-family: Gotham;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: #68696f;
    }
  }
}

// SECOND INFOGRAPHICS SECTION BEING

.PaidAds_container {
  background: #131313 url(./assets/images/SEO_page/bg_circle.png) no-repeat;
  background-position: center;
  background-size: contain;
  padding: 210px 0px 410px;
  -webkit-clip-path: polygon(0% 0%, 100% 4%, 100% 100%, 0% 96%);
  clip-path: polygon(0% 0%, 100% 4%, 100% 100%, 0% 96%);

  @media screen and (max-width: 991px) {
    padding: 160px 0px 50px;
    -webkit-clip-path: polygon(0% 0%, 100% 4%, 100% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 100% 4%, 100% 100%, 0% 100%);
  }
}

.paid_ads_infographics {
  width: 100%;
  height: 1200px;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;

  @media screen and (max-width: 991px) {
    height: auto;
  }
  .start_badge {
    background: #ffffff;
    border-radius: 58px;
    color: #131313;
    padding: 4px 28px;
    @media screen and (max-width: 767px) {
      margin-bottom: 35px;
    }

    label {
      font-family: Gotham;
      font-weight: bold;
      font-size: 32px;
      line-height: 48px;
      color: #131313;
      margin-bottom: 0;
      text-transform: capitalize;
    }
  }

  .center_path {
    width: 7px;
    height: 100%;
    background: #353535;
    position: relative;
  }

  .infographic_step_left {
    h5 {
      @include Boxtitle(right);
      @media screen and (max-width: 991px) {
        display: none;
      }
    }
  }

  .infographic_step_right {
    h5 {
      @include Boxtitle(left);
      @media screen and (max-width: 991px) {
        display: none;
      }
    }
  }

  .stepDot1 {
    &:before {
      background: #c25252;
    }
  }

  .stepDot2 {
    &:before {
      background: #0bbeca;
    }
  }

  .stepDot3 {
    &:before {
      background: #ffc107;
    }
  }

  .stepDot4 {
    &:before {
      background: #36bc64;
    }
  }

  .stepDot5 {
    &:before {
      background: #a959ce;
    }
  }

  .stepDot6 {
    &:before {
      background: #35a1de;
    }
  }

  .infographicStep_RightInner {
    @include InfoSteps_content;
    @media screen and (max-width: 991px) {
      max-width: 100%;
      min-width: unset;
      padding: 26px;
      margin-top: 20px;
    }
    @media (min-width: 992px) and (max-width: 1599px) {
      min-width: unset;
      padding: 25px;
    }

    img {
      position: absolute;
      right: -54px;
      top: -77px;
      z-index: -2;
      @media screen and (max-width: 991px) {
        position: unset;
        display: none;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        right: -20px;
        top: -51px;
        max-width: 90px;
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: 70px;
      left: -100px;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      z-index: 9;
      @media screen and (max-width: 991px) {
        display: none;
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: 85px;
      left: -90px;
      background: #353535;
      width: 100px;
      height: 8px;

      @media screen and (max-width: 991px) {
        left: 50%;
        width: 8px;
        height: 100px;
        top: -60px;
        z-index: -9;
      }
    }
  }

  .infographicStep_LeftInner {
    @include InfoSteps_content;
    @media screen and (max-width: 991px) {
      max-width: 100%;
      min-width: unset;
      padding: 26px;
      margin-top: 20px;
    }
    @media (min-width: 992px) and (max-width: 1599px) {
      min-width: unset;
      padding: 25px;
    }

    img {
      position: absolute;
      left: -46px;
      top: -74px;
      z-index: -2;

      @media screen and (max-width: 991px) {
        position: unset;
        display: none;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        left: -20px;
        top: -51px;
        max-width: 90px;
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: 70px;
      right: -100px;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      z-index: 9;
      @media screen and (max-width: 991px) {
        display: none;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        top: 80px;
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: 85px;
      right: -90px;
      background: #353535;
      width: 100px;
      height: 8px;
      @media screen and (max-width: 991px) {
        left: 50%;
        width: 8px;
        height: 100px;
        top: -60px;
        z-index: -9;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        top: 95px;
      }
    }
  }

  .Step1 {
    @include InfographicSteps(150px, 50px, unset, unset);
    @media screen and (max-width: 991px) {
      position: unset;
      right: 0;
      padding: 0;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      @include InfographicSteps(150px, 13px, unset, unset);
    }
    @media (min-width: 1200px) and (max-width: 1599px) {
      @include InfographicSteps(150px, 25px, unset, unset);
    }
  }

  .Step2 {
    @include InfographicSteps(313px, unset, unset, 50px);
    @media screen and (max-width: 991px) {
      position: unset;
      left: 0;
      padding: 0;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      @include InfographicSteps(313px, unset, unset, 13px);
    }
    @media (min-width: 1200px) and (max-width: 1599px) {
      @include InfographicSteps(313px, unset, unset, 25px);
    }
  }

  .Step3 {
    @include InfographicSteps(560px, 50px, unset, unset);
    @media screen and (max-width: 991px) {
      position: unset;
      right: 0;
      padding: 0;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      @include InfographicSteps(560px, 13px, unset, unset);
    }
    @media (min-width: 1200px) and (max-width: 1599px) {
      @include InfographicSteps(560px, 25px, unset, unset);
    }
  }

  .Step4 {
    @include InfographicSteps(723px, unset, unset, 50px);
    @media screen and (max-width: 991px) {
      position: unset;
      left: 0;
      padding: 0;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      @include InfographicSteps(723px, unset, unset, 13px);
    }
    @media (min-width: 1200px) and (max-width: 1599px) {
      @include InfographicSteps(723px, unset, unset, 25px);
    }
  }

  .Step5 {
    @include InfographicSteps(unset, 50px, 8px, unset);
    @media screen and (max-width: 991px) {
      position: unset;
      right: 0;
      padding: 0;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      @include InfographicSteps(unset, 13px, 8px, unset);
    }
    @media (min-width: 1200px) and (max-width: 1599px) {
      @include InfographicSteps(unset, 25px, 8px, unset);
    }
  }

  .Step6 {
    @include InfographicSteps(unset, unset, -125px, 50px);
    @media screen and (max-width: 991px) {
      position: unset;
      left: 0;
      padding: 0;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      @include InfographicSteps(unset, unset, -125px, 13px);
    }
    @media (min-width: 1200px) and (max-width: 1599px) {
      @include InfographicSteps(unset, unset, -142px, 25px);
    }
  }
}

// SECOND INFOGRAPHICS SECTION END

.co_paid_strategy_column {
  padding: 130px 0 25px;

  @media screen and (max-width: 767px) {
    padding: 60px 0 25px;
  }

  .media {
    margin-bottom: 5rem;
    @media screen and (max-width: 576px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .media-body {
    @media screen and (max-width: 576px) {
      text-align: center;
    }
    h5 {
      font-family: Gotham;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: #131313;
    }

    p {
      font-family: Gotham;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #68696f;
      margin-bottom: 0;
    }
  }
}

.top_ranking_section.ranking_section_2 {
  background: #0ace59;
  padding: 187px 0px;
  -webkit-clip-path: polygon(0% 0%, 100% 8%, 100% 100%, 0% 92%);
  clip-path: polygon(0% 0%, 100% 8%, 100% 100%, 0% 92%);
  margin: 43px 0 -110px;
}
