.founder_section {
    padding-bottom: 100px;

    @media screen and (max-width: 425px) {
        padding-top: 50px;
    }

    .title-founder {
        padding-bottom: 5rem;

        @media screen and (max-width: 425px) {
            padding-bottom: 0rem;
        }
    }

    .content-text {
        p {
            font-family: 'Gotham';
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            color: #9A9CA2;
        }
    }

    .image {
        object-fit: cover;
        max-width: 460px;
        max-height: 570px;
        margin: 0 auto;
    }

    .position {
        font-family: 'Gotham';
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #9A9CA2;
    }
}

.scroll-show-content {
    padding: 100px 0px;
    overflow: hidden;
    padding-bottom: 0px;


    @media screen and (max-width: 425px) {
        padding-top: 130px;
        padding-bottom: 100px;
    }


    .since-year {
        font-weight: bold;
        font-size: 32px;
        line-height: 48px;
        color: #9A9CA2;
    }
}

.horizontal {
    width: 100%;
    height: 100%;
    position: relative;
    overflow-x: hidden;
}

.horizontal:nth-of-type(odd) {
    background-color: #f0f0f0;
}

.horizontal:nth-of-type(even) {
    background-color: #ffffff;
}

section.horizontal .pin-wrap,
section.horizontal .animation-wrap {
    display: flex;
    position: relative;
    z-index: 1;
    height: 100vh;
}


section.horizontal .item {
    position: relative;
    flex: 0 0 400px;
    align-items: center;
    line-height: 1.7;
    height: auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
}


section.horizontal .item img {
    padding: 1rem 2rem;

    @media screen and (max-width: 1440px) {
        padding: 0rem;
    }
}



section.horizontal .animation-wrap.to-right {
    counter-reset: item;
    float: left;
}

section.horizontal .animation-wrap.to-left {
    counter-reset: item 11;
    float: right;
}

section.horizontal .animation-wrap .item:nth-child(2n+2) {
    align-items: flex-start;
}

section.horizontal .animation-wrap .item:nth-child(4n+4) {
    align-items: flex-end;
}

.horizontal .text-center img {
    @media screen and (max-width: 425px) {
        width: 246px;
        height: 106px;
    }
}
