.banner-content-detail-blog {
    h1 {
        text-transform: unset;
        font-size: 80px;
        line-height: 96px;
        letter-spacing: normal;
    }

    p {
        cursor: pointer;
        font-family: 'Gotham';
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
    }

    .author-blog {
        display: flex;
        margin: 5rem 0rem;

        @media screen and (max-width: 425px) {
           display : block;
           margin: 3rem 0rem;
        }

        div {
            margin-left: 5rem;

            @media screen and (max-width: 425px) {
                margin-left: 0rem;
            }
        }
    }
}

.blog-detail {
    background-color: #050505;
    padding: 60px 0px;
    margin-bottom: -105px;
    color: #fff;

    .blog-detail-content {
        padding: 0 10rem;

        @media screen and (max-width: 1024px) {
            padding: 0 5rem;
        }

        @media screen and (max-width: 768px) {
            padding: 0 0rem;
        }

        a {
            color: #0ACE59 !important;
        }

        p {
            font-family: 'Gotham';
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 3rem;
        }

        .img-quote {
            margin-right: 3rem;
        }

        .quote {
            margin-bottom: 3rem;

            p {
                font-weight: 500;
                font-size: 24px;
                line-height: 36px;
                margin-bottom: 1rem;
            }

            ul li {
                margin-left: 20px;
            }
        }

        h2,
        h1,
        h3,
        h4 {
            margin-bottom: 2rem;
        }
    }

    div.image-credit {
        font-family: 'Gotham';
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #CFD1D7;
        text-align: end;
    }
}


.bg-black {
    background-color: #050505;
}

.directions {
    display: flex;
    justify-content: space-between;
    padding: 30px 0px;

    a {
        width: 30%;
        text-decoration: none;

        @media screen and (max-width: 1024px) {
            width: 40%;
        }

        @media screen and (max-width: 425px) {
            width: 45%;
        }

        svg {
            color: #0ACE59;
        }

        p {
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            color: #fff;
        }

        span {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.06em;
            text-transform: uppercase;
            color: #FFFFFF;
            opacity: 0.5;
        }
    }
}

.tag {
    display: flex;
    padding: 30px 0px;
    justify-content: space-between;
    border-bottom: 1px solid #CFD1D7;
    border-top: 1px dashed #CFD1D7;

    @media screen and (max-width: 425px) {
        display: block;
    }

    p {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: #FFFFFF;
        opacity: 0.5;
        padding: 0px 12px;
        margin-bottom: 0px;
    }

    p.border-left {
        border-left: 2px solid #dee2e6 !important;
    }

    .tag-right {
        @media screen and (max-width: 425px) {
            padding-top: 1rem;
        }
        a {
            padding: 0px 1.3rem;
        }
    }
}

.comment {
    padding: 30px 0px;
    border-bottom: 1px solid #CFD1D7;

    img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 25px;

        @media screen and (max-width: 425px) {
            margin-right: 0px;
        }
    }

    h2 {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: #fff;
    }

    p {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #CFD1D7;
        width: 75%;

        @media screen and (max-width: 425px) {
            width: 100%;
        }
    }
}

.next-page {
    padding-bottom: 150px;
}