.find_more_btn {
  a {
    align-items: center;
    padding: 17px 50px;
    margin-top: 55px;
    border-radius: 5px;
    display: inline-block;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
    letter-spacing: 1px;
    font-family: "Gotham";
    text-decoration: none;
    background-image: linear-gradient(
      to right,
      #0ace59 0%,
      #0ace59 51%,
      #0ace59 100%
    );
    background-size: 200% auto;
    transition: 0.5s;
    cursor: pointer;
    font-weight: 600;

    @media (min-width: 768px) and (max-width: 991px) {
      padding: 17px 28px;
    }
    @media screen and (max-width: 767px) {
      padding: 17px 30px;
    }

    i {
      margin-left: 20px;
    }

    &:hover {
      text-decoration: none;
      background-position: right center;
      background-image: linear-gradient(
        to right,
        #027731 0%,
        #0ace59 51%,
        #027731 100%
      );
    }
  }
}

.find_more_white {
  a {
    background-image: linear-gradient(to right, #fff 0%, #fff 51%, #fff 100%);
    color: #0ace59;

    &:hover {
      background-image: linear-gradient(
        to right,
        #464646 0%,
        #131313 51%,
        #464646 100%
      );
      color: #fff;
    }
  }
}

.explore_more {
  padding: 80px 0 30px;

  @media screen and (max-width: 991px) {
    padding: 70px 0 30px;
  }
  a {
    font-family: "Jost", sans-serif;
    text-decoration: none;
    color: #fff;
    font-weight: normal;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.34em;
    text-transform: uppercase;

    &:hover {
      -webkit-mask-image: linear-gradient(
        -75deg,
        rgba(0, 0, 0, 0.6) 30%,
        #000 50%,
        rgba(0, 0, 0, 0.6) 70%
      );
      -webkit-mask-size: 200%;
      animation: shine 2s infinite;
      color: #0ace59;
    }
  }
}

@-webkit-keyframes shine {
  from {
    -webkit-mask-position: 150%;
  }

  to {
    -webkit-mask-position: -50%;
  }
}

@keyframes shine {
  from {
    -webkit-mask-position: 150%;
  }

  to {
    -webkit-mask-position: -50%;
  }
}

.expert_team_col,
.relevant_traffic,
.expert_team_conversion_page,
.top_ranking_section {
  .find_more_btn {
    a {
      background-image: linear-gradient(
        to right,
        #131313 0%,
        #131313 51%,
        #131313 100%
      );
      padding: 34px 42px;
      font-size: 26px;
      line-height: 24px;

      @media screen and (max-width: 767px) {
        padding: 34px 26px;
      }

      @media screen and (max-width: 576px) {
        padding: 34px 15px;
        font-size: 20px;
      }
    }
  }
}
