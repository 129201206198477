﻿

.services-brisbane {
    background-color: #131313;
    padding-top: 115px;
    padding-bottom: 100px;
    clip-path: polygon(0% 0%, 100% 3%, 100% 100%, 0% 98%);
    margin: -240px 0px;
    margin-bottom: -100px;

    @media screen and (max-width: 768px) {
        margin-top: -190px;
    }

    @media screen and (max-width: 425px) {
        margin-top: -130px;
        clip-path: polygon(0% 0%, 100% 1%, 100% 100%, 0% 100%);
    }



    .services-brisbane-content {
        margin-bottom: 2rem;
        font-family: Gotham;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: #CFD1D7;
    }



    ul {
        list-style-image: url('../images/case-studies/icons8-checked 1.svg');

        li {
            font-family: Gotham;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            color: #CFD1D7;
            margin-bottom: 12px;
            margin-left: 30px;
            line-height: 45px;
        }
    }
}
