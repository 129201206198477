
.layout-policy {
    @media screen and (max-width: 768px) {
        height: auto;
    }
}


.your-privacy {
    background-color: #131313;
    color: #fff;
    padding-bottom: 4rem;

    div.title-your-privacy {
        font-weight: bold;
        font-size: 32px;
        line-height: 48px;
    }

    ul, ol {
        margin-bottom: 0rem;
        padding: 0px 20px;
    }

    ol li, ul li, ul li p, p {
        font-weight: 325;
        font-size: 16px;
        line-height: 26px;
        color: #CFD1D7;
    }

    .our-policy-item {
        .title-our-policy-item {
            font-weight: 400;
            font-size: 24px;
            line-height: 36px;
            color: #FFFFFF;
        }

        lo li, ul li, ul li p, p {
            font-weight: 325;
            font-size: 16px;
            line-height: 26px;
            color: #CFD1D7;
        }
    }

    .content-our-policy-item {
        h1, h2, h3, h4, h5 {
            font-weight: 400;
            font-size: 20px;
            line-height: 26px;
            color: #fff;
        }
    }
}


.teams_condition {
    background-color: #131313;
    color: #fff;
    padding: 4rem 0rem;

    h1,
    h2,
    h3,
    h4,
    h5 {
        font-family: 'Gotham';
        font-weight: bold;
        font-size: 22px;
        line-height: 48px;
        color: #FFFFFF;
    }

    p {
        font-weight: 325;
        font-size: 16px;
        line-height: 26px;
        color: #CFD1D7;
    }
}