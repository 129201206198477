.attention_col {
    @media screen and (max-width: 767px) {
        padding: 30px;
    }

    a {
        color: #fff;
        text-decoration: underline;
        display: inline-block;
        line-height: normal;
        transition: 0.3s linear;
    }
}

.optimization_stats_section {
    padding: 100px 0 0;

    .subTitle {
        font-family: Gotham;
        font-weight: bold;
        font-size: 32px;
        line-height: 48px;
        color: #68696f;
    }
}

.competition_outer_col {
    background: #131313;
    -webkit-clip-path: polygon(0% 0%, 100% 8%, 100% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 100% 8%, 100% 100%, 0% 100%);
    padding: 230px 0px;
    margin-bottom: -90px;

    @media screen and (max-width: 767px) {
        -webkit-clip-path: polygon(0% 0%, 100% 5%, 100% 100%, 0% 100%);
        clip-path: polygon(0% 0%, 100% 5%, 100% 100%, 0% 100%);
        margin-bottom: -100px;
    }

    @media screen and (max-width: 567px) {
        -webkit-clip-path: polygon(0% 0%, 100% 3%, 100% 100%, 0% 100%);
        clip-path: polygon(0% 0%, 100% 3%, 100% 100%, 0% 100%);
        padding: 150px 0px;
        margin-top: -60px;
        margin-bottom: -110px;
    }

    .competition_options {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 1fr;
        grid-column-gap: 50px;
        grid-row-gap: 40px;
        margin-top: 72px;

        @media (min-width: 768px) and (max-width: 991px) {
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 35px;
            grid-row-gap: 35px;
        }

        @media screen and (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr);
        }

        .option_box {
            background: #212225;
            padding: 34px;
            color: #cfd1d7;
            position: relative;
            display: -webkit-flex;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            overflow: hidden;
            word-break: break-word;

            @media screen and (max-width: 567px) {
                padding: 28px;
            }

            @media (min-width:992px) and (max-width:1199px) {
                padding: 26px;
            }

            .media {
                align-items: center;

                .media-body {
                    h2 {
                        font-family: Gotham;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 24px;
                        line-height: 36px;

                        @media screen and (max-width: 567px) {
                            font-size: 22px;
                        }
                    }
                }
            }

            &:before {
                content: "";
                position: absolute;
                top: -1px;
                left: -1px;
                border-top: 8px solid #0ace59;
                border-left: 8px solid #0ace59;
                width: 50px;
                height: 50px;
            }
        }
    }
}
