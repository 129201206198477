.Thankyou_popup.fade.show{
    background: #000002;
}

.Thankyou_popup{

.modal-dialog{
    @media screen and (max-width:991px) {
        max-width: 80%;
        margin: 0 auto;
    }
}

    .modal-lg{
        @media screen and (min-width:1600px) {
            max-width: 1366px;
        }
    }
    .modal-content{
        background: #131313 url(./assets/images/thankyou_page/bg_thankyou.webp) no-repeat;
        background-size: cover;
        background-position: center;
        box-shadow: inset 0 -80px 60px -20px #131313;

        .modal-header{
            .close{
                position: absolute;
                top: 20px;
                right: 25px;
                color: #ffff;
                font-size: 40px;
                font-weight: 300;
                opacity: 1;
                cursor: pointer;
                z-index: 99;
            }
        }

        .modal-body{
            .custom_spacing {
                padding: 114px 72px;

                @media screen and (max-width:991px) {
                    padding: 94px 32px;
                }
            }

            .thankyou_content{
                 ul{
                     margin: 0;
                     padding: 0;

                     li{
                        display: block;
                        margin-bottom: 30px;
                        font-family: Gotham;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 30px;
                        color: #fff;
                        overflow: hidden;
                        word-break: break-word;

                        p{
                            margin-bottom: 0;
                        }

                        a{
                            color: #0ACE59;
                            text-decoration: none;
                        }

                        &:first-child{
                            margin-bottom: 50px;
                        }
                     }
                 }   
            }
        }
    }
}