.fade.show {
  background: rgba(10, 206, 89, 0.91);
  padding-right: 0 !important;
}

.free_guide_popup {
  .modal-content {
    padding: 60px 60px 25px;
    border: 0;
    box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.25);
    border-radius: 0;


    @media screen and (max-width:991px) {
      padding: 40px 30px 25px;
    }

    .modal-header {
      .modal-title {
        font-family: Gotham;
        font-style: normal;
        font-weight: 800;
        font-size: 40px;
        line-height: 52px;
        color: #131313;
        @media screen and (max-width:991px) {
          font-size: 30px;
          line-height: 40px;
        }
      }

      button {
        color: #cfd1d7;
        font-size: 35px;
        padding: 0;
        position: absolute;
        top: 25px;
        right: 34px;
        opacity: 1;
      }
    }

    .modal-body {
        p {
          font-family: Gotham;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          color: #68696f;
          margin: 24px 0 50px;
        }

        form {
          input {
            border: 2px solid #68696f;
            box-sizing: border-box;
            border-radius: 5px;
            height: 47px;
            font-family: Gotham;
            margin-top: 36px;

            &:first-child {
              margin-top: 0;
            }
          }
        }
      }

      .modal-footer {
        small {
          color: #68696f;
          font-family: Gotham;

          a {
            color: #0ace59;
          }
        }
      }
  }
}
