.social_media_banner {
  .banner_text {
    h1 {
      text-transform: unset;
      font-size: 80px;
      line-height: 96px;
      letter-spacing: normal;
      @media screen and (max-width: 991px) {
        font-size: 72px;
        line-height: 75px;
      }
      @media screen and (max-width: 767px) {
        font-size: 60px;
        line-height: 68px;
      }
      @media screen and (max-width: 567px) {
        font-size: 38px;
        line-height: 46px;
      }
    }
  }
}

.social_media_strategy_outer {
  background: #212225;
  -webkit-clip-path: polygon(0% 0%, 100% 7%, 100% 100%, 0% 92%);
  clip-path: polygon(0% 0%, 100% 7%, 100% 100%, 0% 92%);
  padding: 194px 0px;
  margin-top: -80px;

  @media (min-width:768px) and (max-width:991px) {
    -webkit-clip-path: polygon(0 0,100% 6%,100% 100%,0 92%);
    clip-path: polygon(0 0,100% 6%,100% 100%,0 92%);
    padding: 194px 0;
    margin-top: -100px;
  }
  @media screen and (max-width: 767px) {
    -webkit-clip-path: polygon(0% 0%, 100% 2%, 100% 100%, 0% 97%);
    clip-path: polygon(0% 0%, 100% 2%, 100% 100%, 0% 97%);
    padding: 115px 0px 135px;
    margin-top: -100px;
  }
  @media screen and (max-width: 567px) {
    -webkit-clip-path: polygon(0% 0%, 100% 3%, 100% 100%, 0% 96%);
    clip-path: polygon(0% 0%, 100% 3%, 100% 100%, 0% 96%);
    padding: 114px 0px 135px;
    margin-top: -150px;
  }

}

// SOCIAL MEDIA STEPS SECTION BEGIN

.social_media_steps {
  border-left: 4px solid #0ace59;
  padding: 20px 20px 0;
  position: relative;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  h5 {
    font-family: Gotham;
    font-weight: 500;
    font-size: 18px;
    line-height: 36px;
    color: #ffffff;
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  p {
    font-family: Gotham;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #ffffff;
    margin-bottom: 40px;
  }

  .step_bottom_ribbon_outer {
    background: #0ace59;
    padding: 4px;
    -webkit-clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
    text-align: center;
    margin-left: -24px;
    max-width: 270px;
    width: 100%;

    .step_bottom_ribbon_inner {
      background: #fff;
      padding: 13px;
      -webkit-clip-path: polygon(0% 0%, 91% 0%, 100% 48%, 91% 100%, 0% 100%);
      clip-path: polygon(0% 0%, 91% 0%, 100% 48%, 91% 100%, 0% 100%);
      text-align: center;
      max-width: 260px;
      width: 100%;

      h4 {
        @media (min-width: 992px) and (max-width: 1199px) {
          font-size: 19px;
        }
      }
    }
  }

  &:after {
    content: "";
    border-bottom: 4px dashed #0ace59;
    position: absolute;
    width: 100%;
    left: -4px;
    bottom: 29px;
    z-index: -1;
  }
}
// SOCIAL MEDIA STEPS SECTION END

.choose_agency_col {
  padding: 74px 0 100px;
  .brands_logos {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: 18px;
    grid-row-gap: 0px;

    @media screen and (max-width: 567px) {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 30px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 12px;
    }
  }

  .text_content_2 {
    p {
      color: #68696f;
    }
  }
}

.social_media_strategy_col {
  background: #131313 url(./assets/images/SEO_page/bg_circle.png) no-repeat;
  background-position: center;
  background-size: contain;
  padding: 197px 0px;
  -webkit-clip-path: polygon(0% 2%, 100% 4%, 100% 100%, 0% 96%);
  clip-path: polygon(0% 2%, 100% 4%, 100% 100%, 0% 96%);
  margin-top: -145px;
  margin-bottom: -150px;

  @media screen and (max-width: 767px) {
    -webkit-clip-path: polygon(0% 1%, 100% 4%, 100% 100%, 0% 98%);
    clip-path: polygon(0% 1%, 100% 4%, 100% 100%, 0% 98%);
    margin-top: -152px;
  }
  @media screen and (max-width: 567px) {
    -webkit-clip-path: polygon(0% 0%, 100% 2%, 100% 100%, 0% 98%);
    clip-path: polygon(0% 0%, 100% 2%, 100% 100%, 0% 98%);
    padding: 120px 0;
  }

  .business_strategy_ideas {
    p {
      font-family: Gotham;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #cfd1d7;
      margin-bottom: 2rem;
    }
  }

  .business_strategy_List {
    ul {
      margin: 0;
      padding: 0;

      li {
        display: block;
        padding: 0 0 30px 55px;
        position: relative;

        p {
          font-family: Gotham;
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          color: #cfd1d7;
          margin-bottom: 0;
        }

        &:before {
          content: "\f270";
          font-family: bootstrap-icons !important;
          color: #0ace59;
          position: absolute;
          top: 0;
          left: 0;
          font-size: 26px;
        }
      }
    }
  }
}

.social_strategy_col {
    margin-top: 200px;

    .phase_tabs {
        display: block;
        text-align: center;
        margin: 10px auto 26px;

        ul {
            margin: 0;
            padding: 0;

            li {
                display: inline-block;
                padding: 0 15px;
                color: #fff;

                @media screen and (max-width: 991px) {
                    padding: 0 10px;
                }

                a {
                    font-family: Gotham;
                    font-size: 16px;
                    line-height: 26px;
                    font-weight: normal;
                    margin-bottom: 0;
                    color: #fff;
                    text-decoration: none;

                    &:focus {
                        color: #0ace59;
                    }
                }

                &:first-child {
                    color: #0ace59;
                }
            }
        }
    }

    .research_options {
        img {
            max-width: 31px;
            max-height: 31px;
            margin-left: 12px;
        }

        h6 {
            font-family: Gotham;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            color: #fff;
            margin: 36px 0 18px;
        }

        p {
            font-family: Gotham;
            font-weight: normal;
            font-size: 16px;
            line-height: 26px;
            color: #CFD1D7;

            @media screen and (max-width: 991px) {
                text-align: justify;
            }
        }
    }
}

.relevant_traffic {
  background: #0ace59;
  padding: 187px 0px;
  -webkit-clip-path: polygon(0% 0%, 100% 8%, 100% 100%, 0% 92%);
  clip-path: polygon(0% 0%, 100% 8%, 100% 100%, 0% 92%);
  margin-bottom: -100px;
}
