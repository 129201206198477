﻿.SEO_Outer {
  background: #131313;
  padding: 120px 0 140px;
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 92%);
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 92%);

  
  @media screen and (max-width:991px) {
    padding-bottom: 130px;
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 94%, 0 84%);
    clip-path: polygon(0 0, 100% 0%, 100% 94%, 0 84%);
  }
  @media screen and (max-width:767px) {
    margin-top: -3px;
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 96%, 0 90%);
    clip-path: polygon(0 0, 100% 0%, 100% 96%, 0 90%);
  }
}


.reasons_outer {
  padding: 70px 0 90px;

  @media screen and (max-width:1400px) {
    padding: 30px 0 50px;
  }

  .reasons_to_choose {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 20px;

    .features_img {
      border: 15px solid rgba(10, 206, 89, 0.1);
      opacity: 1.1;
      border-radius: 50%;
      padding: 23px;
      width: 111px;
      height: 111px;
      margin: 0 auto 35px;
    }

    p {
      font-size: 24px;
      line-height: 36px;
      font-weight: 500;
      font-family: "Gotham";
      text-align: center;
      color: #68696f;
    }
  }
}

.conversion {
  background-color: #131313;
  background-image: url("../../assets/images/ConversionOptimisationbg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-clip-path: polygon(0% 0%, 100% 8%, 100% 100%, 0% 92%);
  clip-path: polygon(0% 0%, 100% 8%, 100% 100%, 0% 92%);
  padding: 194px 0px 135px;
  margin-bottom: -118px;

  @media screen and (max-width:767px) {
    -webkit-clip-path: polygon(0% 0%, 100% 8%, 100% 100%, 0% 94%);
    clip-path: polygon(0% 0%, 100% 8%, 100% 100%, 0% 94%);
    margin-bottom: -120px;
  }

  .sub_heading {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    color: #ffffff;
    max-width: 783px;
    margin: 30px auto 51px;
    width: 100%;
  }

  .text_content {
    padding-left: 60px;
    margin-top: 20px;
    @media screen and (max-width:991px) {
      padding-left: 5px;
    }
  }
  .find_more_btn a {
    margin-top: 10px;
  }
}

.help-sections {
  background-image: url("./assets/images/help/help_bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 92%);
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 92%);
  padding: 221px 0px 185px;
  position: relative;
  z-index: 1;
  background-color: #008937;
  mix-blend-mode: multiply;
  margin-bottom: 70px;

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: #008937;
    opacity: 0.6;
    z-index: -1;
  }

  p.Sub_heading {
    padding-right: 80px;
    @media screen and (max-width:767px) {
      padding-right: 0;  
    }
  }
  .text_content{
    p{
      color: #fff;
    }
  }
  .highlight{
    font-family: Gotham;
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 55px;
    text-transform: uppercase;
    color: #131313;
    display: table;
    background: #FFFFFF;
    clear: both;
    padding: 0px 14px;
    margin-bottom: 20px;
    position: relative;
    top: 6px;

    @media screen and (max-width:576px) {
      font-size: 24px;
      line-height: 40px;
    }
  }

  .Global_heading {
    color: #ffffff;
    padding-bottom: 60px;
  }
}

.strategic-services {
  display: inline-block;
  width: 100%;
  background: url(./assets/images/SEO_page/bg_circle_fade.png) no-repeat;
  background-position: 100%;
  background-size: contain;
  padding: 110px 0 50px;

  @media screen and (max-width:767px) {
    padding: 0 0 50px;
  }

  .container-fluid {
    max-width: 1700px;
  }
  .Global_heading {
    padding-bottom: 100px;
    @media screen and (max-width:767px) {
      padding-bottom: 50px;
    }
  }

  .services-list {
    .col {
      text-align: center;
      padding: 0px 34px;
    }

    .block {
      border: 4px solid #131313;
      text-align: left;
      margin: auto;
      height: 330px;
      margin-bottom: 70px;
      padding: 20px;
      display: -webkit-flex;
      display: flex;
      align-items: flex-end;
      position: relative;
      flex-wrap: wrap;
      width: 270px;

      @media screen and (max-width:576px) {
        width: 250px;
      }

      &:hover {
        border-color: #0ace59;

        .title {
          order: unset;
          color: #fff;
          position: relative;
        }
        .icon {
          opacity: 1;
          visibility: visible;
          max-height: none;
        }

        .content {
          max-height: 100%;
          opacity: 1;
          color: #fff;
          position: relative;
        }

        &:before {
          opacity: 1;
          visibility: visible;
          height: 100%;
        }
      }

      &:before {
        content: "";
        background: #0ace59;
        height: 0;
        width: 100%;
        position: absolute;
        bottom: 0px;
        left: 0px;
        opacity: 0;
        visibility: hidden;
        transition: all 0.5s ease;
        z-index: 0;
      }
      .title {
        width: 100%;
        order: 2;
        color: #000;
        font-family: Gotham;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 48px;

        @media screen and (max-width:991) {
          order: 1;
          margin-bottom: 15px;
          font-size: 29px;
        }
      }
      .content {
        opacity: 0;
        max-height: 0px;
        width: 100%;
        transition: all 0.5s ease;
        font-family: Gotham;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 26px;

        @media screen and (max-width:767px) {
          opacity: 1;
          order: 2;
          max-height: 100%;
        }
      }
    }
  }
}
