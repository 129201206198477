@mixin SEO_steps($top, $right, $bottom, $left) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  overflow: hidden;
  word-break: break-word;
}
@mixin steps_Para {
  font-family: Gotham;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #d7cfd5;
  margin: 0 4px 20px;
}

@mixin steps_title {
  font-family: Gotham;
  font-weight: 500;
  font-size: 23px;
  line-height: 36px;
  text-align: center;
  color: #0ace59;
}

@mixin mobileSteps {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 45px;
  position: unset;
}

.steps_counter {
  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: column-reverse;
  }
}

// INFOGRAPHICS SECTION BEGIN

.seo_steps_infographic_outer {
  display: block;
  width: 100%;
  position: relative;
  margin: 150px 0;

  .step_path_line {
    position: relative;

    img {
      max-width: 1140px;
      margin: 0 auto;
      display: block;
      @media screen and (max-width: 991px) {
        display: none;
      }
    }

    .steps_width {
      max-width: 300px;

      @media screen and (max-width: 991px) {
        max-width: 100%;
        padding: 0 20px;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        max-width: 260px;
      }

      h5 {
        font-family: Gotham;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.39em;
        text-transform: uppercase;
        color: #68696f;
        white-space: nowrap;
      }
      h1 {
        font-family: Gotham;
        font-weight: 800;
        font-size: 112px;
        line-height: 112px;
        text-align: center;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: #0ace59;
      }
    }
  }

  .Seo_step_1 {
    @include SEO_steps(50px, unset, unset, 20px);

    p {
      @include steps_Para;
      @media screen and (max-width: 991px) {
        text-align: right;
        max-width: 100%;
      }
    }

    h3 {
      @include steps_title;
      @media screen and (max-width: 991px) {
        text-align: right;
        max-width: 100%;
      }
    }
    @media screen and (min-width: 1600px) {
      left: 160px;
    }
    @media screen and (max-width: 991px) {
      @include mobileSteps;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      @include SEO_steps(40px, unset, unset, 17px);
    }
  }

  .Seo_step_2 {
    @include SEO_steps(unset, unset, 50px, 270px);

    p {
      @include steps_Para;
      @media screen and (max-width: 991px) {
        text-align: left;
        max-width: 100%;
      }
    }

    h3 {
      @include steps_title;
      @media screen and (max-width: 991px) {
        text-align: left;
        max-width: 100%;
      }
    }

    @media screen and (min-width: 1600px) {
      left: 404px;
    }
    @media screen and (max-width: 991px) {
      @include mobileSteps;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      @include SEO_steps(-132px, unset, 50px, 233px);
    }
  }

  .Seo_step_3 {
    @include SEO_steps(50px, 280px, unset, unset);

    p {
      @include steps_Para;
      @media screen and (max-width: 991px) {
        text-align: right;
        max-width: 100%;
      }
    }

    h3 {
      @include steps_title;
      @media screen and (max-width: 991px) {
        text-align: right;
        max-width: 100%;
      }
    }
    @media screen and (min-width: 1600px) {
      right: 410px;
    }
    @media screen and (max-width: 991px) {
      @include mobileSteps;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      @include SEO_steps(40px, 228px, unset, unset);
    }
  }

  .Seo_step_4 {
    @include SEO_steps(unset, 20px, 50px, unset);

    p {
      @include steps_Para;
      @media screen and (max-width: 991px) {
        text-align: left;
        max-width: 100%;
      }
    }
    h3 {
      @include steps_title;
      @media screen and (max-width: 991px) {
        text-align: left;
        max-width: 100%;
      }
    }
    @media screen and (min-width: 1600px) {
      right: 140px;
    }
    @media screen and (max-width: 991px) {
      @include mobileSteps;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      @include SEO_steps(-132px, 4px, 50px, unset);
    }
  }
}

.divider {
  width: 144px;
  height: 0px;
  display: block;
  border: 1px solid #ffffff;
  margin: 7px auto 16px;

  @media screen and (max-width: 991px) {
    width: 0;
    height: 144px;
    margin: 0 25px;
  }
}

// INFOGRAPHICS SECTION END

.stats_graph_outer {
  padding: 140px 0;

  @media screen and (max-width: 576px) {
    padding: 0;
    margin-top: 0;
  }

  .seo_stats_col {
    .icon_img {
      width: 60px;
      height: 60px;
      background: #f3f3f3;
      display: -webkit-flex;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      margin-bottom: 15px;
    }
    .Global_heading {
      @media screen and (min-width: 1401px) {
        font-size: 75px;
      }

      @media screen and (min-width: 1600px) {
        font-size: 80px;
      }
    }

    p {
      font-family: Gotham;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #68696f;
    }
  }
}

.counter {
  animation-duration: 1s;
  animation-delay: 0s;
}

.google_ad_graph_col {
  .media-body {
    h5 {
      font-family: Gotham;
      font-weight: bold;
      font-size: 32px;
      line-height: 48px;
      color: #68696f;
    }
  }

  .graph_img {
    img {
      display: block;
      margin: 98px auto 0;
      @media screen and (max-width: 767px) {
        margin-bottom: 100px;
      }
    }
  }
}

.seo_strategy_col {
    background: #131313 url(../images/SEO_page/bg_circle.png) no-repeat;
    background-position: center;
    background-size: contain;
    padding: 197px 0px;
    -webkit-clip-path: polygon(0% 0%, 100% 8%, 100% 100%, 0% 92%);
    clip-path: polygon(0% 0%, 100% 8%, 100% 100%, 0% 92%);

    @media screen and (max-width: 991px) {
        -webkit-clip-path: polygon(0% 0%, 100% 5%, 100% 99%, 0% 100%);
        clip-path: polygon(0% 0%, 100% 5%, 100% 99%, 0% 100%);
        padding-bottom: 140px;
    }

    .phase_tabs {
        display: block;
        text-align: center;
        margin: 10px auto 26px;

        ul {
            margin: 0;
            padding: 0;

            li {
                display: inline-block;
                padding: 0 15px;
                color: #fff;

                @media screen and (max-width: 991px) {
                    padding: 0 10px;
                }

                a {
                    font-family: Gotham;
                    font-size: 16px;
                    line-height: 26px;
                    font-weight: normal;
                    margin-bottom: 0;
                    color: #fff;
                    text-decoration: none;

                    &:focus {
                        color: #0ace59;
                    }

                    &.active {
                        background-color: #0ace59 !important;
                        color: #fff;
                        padding: 10px;
                        border-radius: 5px;
                    }
                }

                &:first-child {
                    color: #0ace59;
                }
            }
        }
    }

    .research_options {
        @media screen and (max-width: 991px) {
            margin-bottom: 50px;
        }

        img {
            max-width: 31px;
            max-height: 31px;
            margin-left: 12px;
        }

        h6 {
            font-family: Gotham;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            color: #fff;
            margin: 36px 0 18px;
        }

        p {
            font-family: Gotham;
            font-weight: normal;
            font-size: 16px;
            line-height: 26px;
            color: #CFD1D7;

            @media screen and (max-width: 991px) {
                text-align: justify;
            }
        }
    }
}

.top_ranking_section {
  background: #0ace59;
  padding: 187px 0px;
  -webkit-clip-path: polygon(0% 0%, 100% 8%, 100% 100%, 0% 92%);
  clip-path: polygon(0% 0%, 100% 8%, 100% 100%, 0% 92%);
  margin: -110px 0;
}
