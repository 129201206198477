@font-face {
    font-family: 'Gotham';
    src: url('../fonts/fonts/Gotham-Book.eot');
    src: url('../fonts/Gotham-Book.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gotham-Book.woff2') format('woff2'),
    url('../fonts/Gotham-Book.woff') format('woff'),
    url('../fonts/Gotham-Book.ttf') format('truetype'),
    url('../fonts/Gotham-Book.svg#Gotham-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Medium.eot');
    src: url('../fonts/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gotham-Medium.woff2') format('woff2'),
    url('../fonts/Gotham-Medium.woff') format('woff'),
    url('../fonts/Gotham-Medium.ttf') format('truetype'),
    url('../fonts/Gotham-Medium.svg#Gotham-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Bold.eot');
    src: url('../fonts/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gotham-Bold.woff2') format('woff2'),
    url('../fonts/Gotham-Bold.woff') format('woff'),
    url('../fonts/Gotham-Bold.ttf') format('truetype'),
    url('../fonts/Gotham-Bold.svg#Gotham-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Black.eot');
    src: url('../fonts/Gotham-Black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gotham-Black.woff2') format('woff2'),
    url('../fonts/Gotham-Black.woff') format('woff'),
    url('../fonts/Gotham-Black.ttf') format('truetype'),
    url('../fonts/Gotham-Black.svg#Gotham-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Light.eot');
    src: url('../fonts/Gotham-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gotham-Light.woff2') format('woff2'),
    url('../fonts/Gotham-Light.woff') format('woff'),
    url('../fonts/Gotham-Light.ttf') format('truetype'),
    url('../fonts/Gotham-Light.svg#Gotham-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Ultra.eot');
    src: url('../fonts/Gotham-Ultra.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gotham-Ultra.woff2') format('woff2'),
    url('../fonts/Gotham-Ultra.woff') format('woff'),
    url('../fonts/Gotham-Ultra.ttf') format('truetype'),
    url('../fonts/Gotham-Ultra.svg#Gotham-Ultra') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}