.service-list {
    background: #050505 url(../images/SEO_page/bg_circle.png) no-repeat;
    background-position: center;
    background-size: contain;
    padding: 100px 0px;
    padding-top : 200px;
    margin-bottom: -100px;

    @media screen and (max-width: 425px) {
        padding-top: 40px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-width: 555px;
        max-height: 450px;
        padding-bottom: 4rem;

        @media screen and (max-width: 768px) {
            padding-bottom: 1rem;
            text-align: center;
        }

    }

    .content {
        padding-top: 1rem;

        @media screen and (max-width: 1024px) {
            padding-top: 0px;
        }

        @media screen and (max-width: 768px) {
            margin-bottom: 3rem;
        }

        p {

            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: #9A9CA2;

        }

        .find_more_btn a {
            margin: 0px;
        }
    }

    .pading-left {
        padding-left: 5rem;

        @media screen and (max-width: 1024px) {
            padding-left: 1rem;
        }

    }

    .pading-right {
        padding-right: 5rem;

        @media screen and (max-width: 1024px) {
            padding-right: 1rem;
        }
    }
}

.banner-blog-detail {
    @media screen and (max-width: 768px) {
        height: auto;
    } 
    h1 {
        @media screen and (max-width: 425px) {
            font-size: 56px;
            line-height: 66px;
        }
    }
}