@mixin subHeading {
  color: #131313;
  text-align: left;
  margin-bottom: 15px;
  @media screen and (max-width: 991px) {
    font-size: 27px;
  }
}

.lead_gen_journey {
  padding: 100px 0;

  @media screen and (max-width: 991px) {
    margin-top: -70px;
  }
  @media screen and (max-width: 576px) {
    padding-bottom: 0;
  }
}

.conversion_steps1 {
  .sub_heading_2 {
    @include subHeading;
  }
  .Global_heading {
    color: #583fbb;
    padding: 0;
    @media screen and (max-width: 991px) {
      font-size: 55px;
    }
  }
}

.conversion_steps2 {
  .sub_heading_2 {
    @include subHeading;
  }
  .Global_heading {
    color: #c556c0;
    padding: 0;
    @media screen and (max-width: 991px) {
      font-size: 55px;
    }
  }
}

.conversion_steps3 {
  .sub_heading_2 {
    @include subHeading;
  }
  .Global_heading {
    color: #5edd22;
    padding: 0;
    @media screen and (max-width: 991px) {
      font-size: 55px;
    }
  }
}

.conversion_steps4 {
  .sub_heading_2 {
    @include subHeading;
  }
  .Global_heading {
    color: #dd2222;
    padding: 0;
    @media screen and (max-width: 991px) {
      font-size: 55px;
    }
  }
}

.lead_generation_col {
  background: #131313;
  -webkit-clip-path: polygon(0% 0%, 100% 8%, 100% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 100% 8%, 100% 100%, 0% 100%);
  padding: 194px 0px 135px;

  @media screen and (max-width: 767px) {
    -webkit-clip-path: polygon(0% 0%, 100% 4%, 100% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 100% 4%, 100% 100%, 0% 100%);
    padding-top: 140px;
    padding-bottom: 0;
  }

  .conversionInfographic {
    margin-bottom: 115px;
  }

  .about_lead_generation {
    font-family: Gotham;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #ffffff;
  }

  .about_lead {
    font-family: Gotham;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    color: #cfd1d7;
  }
  .lead_categories {
    a {
      text-decoration: none;
      display: block;
      background: #242020;
      padding: 7px 12px;
      text-align: center;
      font-family: Gotham;
      font-weight: bold;
      font-size: 20px;
      line-height: 36px;
      overflow: hidden;
      word-break: break-word;

      @media screen and (max-width: 576px) {
        margin-bottom: 22px;
      }

      @media (min-width:992px) and (max-width: 1199px) {
        padding: 7px 3px;
      }
    }
    h5 {
      letter-spacing: 0.225em;
      text-transform: uppercase;
    }
    label {
      font-family: Gotham;
      font-weight: normal;
      font-size: 15px;
      line-height: 26px;
      margin: 0;
    }
  }

  .col_TOFU {
    h5,
    label {
      color: #f38230;
    }
  }

  .col_MOFU {
    h5,
    label {
      color: #09af4c;
    }
  }

  .col_BOFU {
    h5,
    label {
      color: #765da5;
    }
  }
}

.conversion_stepsCol {
    padding: 300px 0 100px;
    position: relative;
    background: #fff url(./assets/images/SEO_page/bg_circle_fade.png) no-repeat;
    background-position: 100%;
    background-size: contain;
    margin-bottom: -90px;

    &:before {
        content: "";
        background: url('/assets/images/SEO_page/arrow_bg.svg') center no-repeat !important;
        background-size: auto;
        background-position: center;
        position: absolute;
        top: -2px;
        left: 0;
        width: 100%;
        height: 180px;
        display: -webkit-flex;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        z-index: 99;
        margin: 0 auto;
    }

    .media {
        margin-bottom: 80px;

        .media-body {
            h5 {
                font-family: Gotham;
                font-weight: 500;
                font-size: 20px;
                line-height: 20px;
                letter-spacing: 0.08em;
                text-transform: uppercase;
                color: #0ace59;
                margin-bottom: 5px;
            }

            p {
                font-family: Gotham;
                font-weight: normal;
                font-size: 16px;
                line-height: 26px;
                color: #68696f;
                margin: 15px 0 0 0;
            }
        }
    }
}

.expert_team_conversion_page {
  background: #0ace59;
  padding: 187px 0px 200px;
  -webkit-clip-path: polygon(0% 0%, 100% 8%, 100% 100%, 0% 92%);
  clip-path: polygon(0% 0%, 100% 8%, 100% 100%, 0% 92%);
  margin-top: 43px;
  margin-bottom: -150px;

  .text_content {
    p {
      color: #fff;
    }
  }
}

.conversion_infographics {
  @media screen and (max-width: 767px) {
    padding-bottom: 0;
  }

  .text_content {
    p {
      @media screen and (max-width: 767px) {
        margin-bottom: 0;
      }
    }
  }
}
