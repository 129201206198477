@mixin bannerCol($background) {
    background: $background;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: inset 0 -80px 60px -20px #383838;
}

.box-shadow {
    box-shadow: inset 0 -80px 60px -20px #131313 !important;
}


.bg_banner {
    @include bannerCol(#131313);

    .navbar-brand {
        max-width: 155px;
        overflow: hidden;

        @media screen and (max-width:991px) {
            max-width: 95px;
            height: auto;
        }
    }
}

.navbar.navbar-expand-lg {
    span {
        img {
            cursor: pointer;
        }
    }
}

.navbar-expand-lg {
    justify-content: space-between;
    cursor: default;
    margin-top: 15px;
}

.banner_text {
    min-height: 520px;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    @media screen and (max-width:991px) {
        min-height: 250px;
    }

    h1, h2 {
        color: #fff;
        font-family: 'Gotham';
        text-transform: uppercase;
        font-size: 112px;
        line-height: 112px;
        letter-spacing: 0.08em;
        font-weight: 800;
        overflow: hidden;
        word-break: break-word;



        @media screen and (max-width:1200px) {
            font-size: 100px;
            line-height: 100px;
        }

        @media screen and (max-width:991px) {
            font-size: 72px;
            line-height: 75px;
        }

        @media screen and (max-width:767px) {
            font-size: 45px;
            line-height: 50px;
        }

        @media screen and (max-width:576px) {
            font-size: 33px;
            line-height: 44px;
        }
    }

    h2 {
        .Sub_heading {
            font-size: 50px;

            @media screen and (max-width:1200px) {
                font-size: 50px;
                line-height: 50px;
            }

            @media screen and (max-width:991px) {
                font-size: 36px;
                line-height: 38px;
            }

            @media screen and (max-width:767px) {
                font-size: 24px;
                line-height: 26px;
            }

            @media screen and (max-width:576px) {
                font-size: 20px;
                line-height: 22px;
            }
        }
    }
}

.content-in-banner .left {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.875rem;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
    transform: rotate(-90deg);
    position: absolute;
    left: -8rem;
    bottom: 14rem;
}

.seo-content-in-banner {
    .left {
        font-weight: 500;
        font-size: 0.7rem;
        line-height: 1.875rem;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #FFFFFF;
        transform: rotate(-90deg);
        position: absolute;
        left: -20rem;
        bottom: 22rem;

        .line-straight {
            width: 4.375rem;
            height: 1px;
            background: #fff;
        }
    }

    .left-services {
        left: -15rem;
    }
}


.bg_conversion_banner {
    @include bannerCol(#131313 url(./assets/images/conversion_optimization/bg_conversion.webp));
}

.bg_email_banner {
    @include bannerCol(#131313 url(./assets/images/email_marketing/email_market_banner.webp));
}

.bg_lead_banner {
    @include bannerCol(#131313 url(./assets/images/lead_page/lead_bg.webp));
}

.bg_paidAd_banner {
    @include bannerCol(#131313 url(./assets/images/paid_ads/paid_ads_banner.webp));
}

.bg_seo_banner {
    @include bannerCol(#131313 url(./assets/images/SEO_page/SEO_banner.webp));
}

.social_media_banner {
    @include bannerCol(#131313 url(./assets/images/social_media/social_media_banner.webp));
}

.bg_speed_banner {
    @include bannerCol(#131313 url(./assets/images/speed_optimisation/bg_speed.webp));
}

a.menu-right {
    &.active {
        color: #131313 !important;
        text-decoration: none !important;
    }
}
